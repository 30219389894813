import { useWatch } from "react-hook-form";
import { useQuery } from "react-query";

import { FormMemberAutocomplete } from "@/components/MemberAutocomplete";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { BookingMemberProfile } from "@/components/BookingMemberProfile";
import { getMember, getMemberTodayBookings } from "@/services/member";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

import type { CheckInDialogState } from "@/features/check-in/components/CheckInDialog";
import type { Control } from "react-hook-form";

export type MergeFormMemberProfileControl = Control<CheckInDialogState>;

export function FormMemberProfileAutocomplete({
  control,
  queryKey,
}: {
  control?: MergeFormMemberProfileControl;
  queryKey: string;
}) {
  const id = useWatch({ name: "member.id", control });

  const { data, isLoading: memberLoading } = useQuery(
    [queryKey, "member", id],
    () => getMember(id),
    { enabled: !!id }
  );

  const { data: bookings, isLoading: bookingLoading } = useQuery(
    [queryKey, "bookings", id],
    () => getMemberTodayBookings(id),
    { enabled: !!id }
  );

  const loadingQuery = memberLoading || bookingLoading;

  return (
    <Stack gap={5}>
      <Box
        display="flex"
        gridTemplateColumns="50px 1fr"
        gap={3}
        alignItems="center"
      >
        <QrCodeScannerIcon fontSize="large" />
        <Typography variant="subtitle1">
          สแกนคิวอาร์โค้ด เพื่อตรวจสอบข้อมูลของสมาชิก
        </Typography>
      </Box>
      <FormMemberAutocomplete
        label="หมายเลขสมาชิก ชื่อ หรือเบอร์โทรศัพท์"
        name="member"
        control={control}
        sx={{ gridColumn: "1/-1" }}
        required
      />
      {loadingQuery ? (
        <Box display="grid" sx={{ placeItems: "center" }} height={544}>
          <CircularProgress disableShrink />
        </Box>
      ) : (
        data && <BookingMemberProfile member={data} bookings={bookings} />
      )}
    </Stack>
  );
}
