import { Autocomplete, TextField } from "@mui/material";
import { useController } from "react-hook-form";
import { useQuery } from "react-query";

import { getProductMemberships } from "@/services/product";
import { transformDurationISOtoObject } from "@/lib/duration";

import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";
import type { TextFieldProps } from "@mui/material";
import type { AutocompleteProps as MuiAutocompleteProps } from "@mui/material/Autocomplete/Autocomplete";
import type { DurationObjectUnits } from "luxon";

export type MembershipAutocompleteValue = {
  id: number;
  name: string;
  duration: DurationObjectUnits;
  price: number;
};

export type MembershipAutocompleteProps = {
  label?: string;
  TextFieldProps?: Partial<TextFieldProps>;
} & Omit<
  MuiAutocompleteProps<MembershipAutocompleteValue, false, false, false>,
  "renderInput" | "options"
>;

function useProductMemberships() {
  return useQuery("product-memberships", () =>
    getProductMemberships("limit=1000")
  );
}

export function MembershipAutocomplete({
  label,
  TextFieldProps,
  ...props
}: MembershipAutocompleteProps) {
  const { data: raw, isLoading } = useProductMemberships();

  const data = raw?.data ?? [];
  const options = data.map(
    ({ id, name, productMembership, price, branches }) => ({
      id: productMembership.id,
      name,
      duration: transformDurationISOtoObject(productMembership.durationIso),
      price,
      branches,
      productId: id,
    })
  );

  return (
    <Autocomplete
      options={options}
      loading={isLoading}
      getOptionLabel={({ name }) => name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField label={label} {...params} {...TextFieldProps} />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      {...props}
    />
  );
}

export function FormMembershipAutocomplete<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  required = false,
  ...props
}: { required?: boolean } & Pick<
  ControllerProps<TFieldValues, TName>,
  "name" | "control"
> &
  Omit<
    MembershipAutocompleteProps,
    "value" | "name" | "TextFieldProps" | "onChange"
  >) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <MembershipAutocomplete
      value={field.value ?? null}
      onChange={(_, newValue) => field.onChange(newValue)}
      TextFieldProps={{
        inputRef: field.ref,
        onBlur: field.onBlur,
        error: fieldState.invalid,
        helperText: fieldState.error?.message,
        required,
      }}
      {...props}
    />
  );
}
