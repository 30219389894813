import {
  Box,
  Breadcrumbs,
  Card,
  CircularProgress,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "react-query";

import { getDocumentTitle, useRequireParams } from "@/utils";
import { ProductPTDetail } from "@/features/product/pages/ProductPTDetailPage/ProductPTDetail";

import { getProductPT } from "@/services/product";

export function PTDetailPage() {
  const location = useLocation();
  const path = location?.pathname ?? "";
  const isAnother = path.includes("another");

  const { id, memberId } = useRequireParams(["id", "memberId"]);

  const { data, isLoading } = useQuery("product-pt", () => getProductPT(id));

  const isLoaded = !isLoading && typeof data !== "undefined";

  const title = data?.name ?? "Product PT Detail";

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle(title)}</title>
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 4.5 }}
      >
        <MuiLink component={Link} color="text.secondary" to="/members">
          สมาชิก
        </MuiLink>
        <MuiLink
          component={Link}
          color="text.secondary"
          to={isAnother ? "/members/another" : "/members"}
        >
          {isAnother ? "สมาชิกอื่นๆ" : "สมาชิกที่ดูแล"}
        </MuiLink>
        <MuiLink
          component={Link}
          color="text.secondary"
          to={
            isAnother ? `/members/another/${memberId}` : `/members/${memberId}`
          }
        >
          ข้อมูลสมาชิก
        </MuiLink>
        <Typography color="text.primary">รายละเอียดแพ็กเกจเทรนเนอร์</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 2.5 }}>
        {isLoaded ? (
          <ProductPTDetail data={data} />
        ) : (
          <Box display="grid" sx={{ placeItems: "center" }} height={228}>
            <CircularProgress disableShrink />
          </Box>
        )}
      </Card>
    </Box>
  );
}
