import {
  Box,
  Card,
  CardContent,
  Stack,
  Button,
  TableContainer,
  Table,
  TableHead,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";

import { PurchaseProductEditorDialog } from "@/features/product/components/PurchaseProductEditorDialog";
import { QueryTextField } from "@/components/QueryTextField";
import { DataTableBody } from "@/components/DataTableBody";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { getDocumentTitle } from "@/utils";
import { getProducts } from "@/services/product";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";
import { ItemsTotal } from "@/components/ItemsTotal";

import { ProductTableRow, ProductTableRowHeader } from "./ProductTableRow";

const QUERY_KEY = "product";

export function ProductListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { staffId, name } = useCurrentUser();
  const query = searchParams.toString();

  const { data: raw, isLoading } = useQuery([QUERY_KEY, query], () =>
    getProducts(query)
  );

  function purchase() {
    searchParams.set("dialog", "product-purchase");
    setSearchParams(searchParams);
  }

  const dialog = searchParams.get("dialog");

  const purchaseDialog = {
    open: dialog === "product-purchase",
    onClose: () => {
      searchParams.delete("dialog");
      setSearchParams(searchParams, { replace: true });
    },
    sale: { id: staffId, name },
  };

  const data = raw?.data ?? [];
  const total = raw?.total;

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle("สินค้า")}</title>
      </Helmet>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={4}
            mb={5}
          >
            <QueryTextField sx={{ width: 600 }} placeholder="ค้นหาชื่อ" />
            <Stack direction="row" gap={2}>
              <Button
                variant="contained"
                size="large"
                onClick={purchase}
              >
                ซื้อสินค้า
              </Button>
            </Stack>
          </Stack>
          <ItemsTotal count={total} />
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <ProductTableRowHeader />
              </TableHead>
              <DataTableBody loading={isLoading} data={data}>
                {data.map((item) => (
                  <ProductTableRow key={item.id} data={item} />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <PurchaseProductEditorDialog {...purchaseDialog} />
      <SearchParamsPagination total={total} />
    </Box>
  );
}
