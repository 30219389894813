import {
  Box,
  Card,
  CardContent,
  Stack,
  Button,
  TableContainer,
  Table,
  TableHead,
  ButtonGroup,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { useAtom } from "jotai";

import { QueryTextField } from "@/components/QueryTextField";
import { DataTableBody } from "@/components/DataTableBody";
import { ItemsTotal } from "@/components/ItemsTotal";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { getDocumentTitle, mapOptional, pickListTableParams } from "@/utils";
import { getAnotherMembers } from "@/services/member";
import { MemberStatus } from "@/models";
import { formatMemberStatus } from "@/formatter";
import { selectedBranchAtom } from "@/atom/global";

import {
  MemberTableRow,
  MemberTableRowHeader,
} from "../../components/MemberTableRow";

const QUERY_KEY = "another-members";

export function AnotherMemberListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = pickListTableParams(searchParams);
  const [selectedBranch] = useAtom(selectedBranchAtom);
  const { data: raw, isLoading } = useQuery(
    [QUERY_KEY, query, selectedBranch],
    () => getAnotherMembers(query)
  );

  const status = searchParams.get("status") as MemberStatus | undefined;
  const setStatus = (s: MemberStatus | null) => () => {
    if (s) {
      searchParams.set("status", s);
    } else {
      searchParams.delete("status");
    }

    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const data = raw?.data ?? [];
  const total = raw?.total;

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle("Member")}</title>
      </Helmet>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
            mb={5}
          >
            <QueryTextField
              sx={{ width: 400 }}
              placeholder="ค้นหาด้วยหมายเลขสมาชิกหรือชื่อ"
            />
            <ButtonGroup variant="contained" size="large" color="inherit">
              {[
                null,
                MemberStatus.Active,
                MemberStatus.Expired,
                MemberStatus.Ban,
              ].map((s) => (
                <Button
                  key={s}
                  onClick={setStatus(s)}
                  sx={{ bgcolor: s === status ? "grey.100" : "grey.300" }}
                >
                  {mapOptional(s, formatMemberStatus) ?? "ทั้งหมด"}
                </Button>
              ))}
            </ButtonGroup>
          </Stack>
          <ItemsTotal count={total} />
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <MemberTableRowHeader />
              </TableHead>
              <DataTableBody loading={isLoading} data={data}>
                {data.map((item) => (
                  <MemberTableRow key={item.id} data={item} />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
    </Box>
  );
}
