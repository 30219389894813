import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import { BookingStatus, BookingType } from "@/models";
import { mapOptional, pickListTableParams, useRequireParams } from "@/utils";
import { DataTableBody } from "@/components/DataTableBody";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { ItemsTotal } from "@/components/ItemsTotal";
import { formatBookingStatus } from "@/formatter";
import { getMemberBookings } from "@/services/member";

import {
  PTReservationTableHeaderRow,
  PTReservationTableRow,
} from "./PTReservationTableRow";

const QUERY_KEY = "ptReservation";

export function PTReservationListPage() {
  const { memberId } = useRequireParams(["memberId"]);
  const [searchParams, setSearchParams] = useSearchParams();

  const status = searchParams.get("status") as BookingStatus | undefined;
  const setStatus = (s: BookingStatus | null) => () => {
    if (s) {
      searchParams.set("status", s);
    } else {
      searchParams.delete("status");
    }

    searchParams.delete("page");
    setSearchParams(searchParams, { replace: true });
  };

  const query = pickListTableParams(searchParams);

  const { data: raw, isLoading } = useQuery([QUERY_KEY, query], () =>
    getMemberBookings({
      memberId: +memberId,
      type: BookingType.PersonalTraining,
      query,
    })
  );

  const data = raw?.data ?? [];
  const total = raw?.total ?? 0;

  return (
    <Stack gap={3}>
      <Card>
        <CardContent>
          <Stack>
            <Stack direction="row" justifyContent="space-between" mb={3}>
              <ButtonGroup variant="contained" size="large" color="inherit">
                {[
                  null,
                  BookingStatus.Booked,
                  BookingStatus.Pending,
                  BookingStatus.Cancelled,
                ].map((s) => (
                  <Button
                    key={s}
                    onClick={setStatus(s)}
                    sx={{ bgcolor: s === status ? "grey.100" : "grey.300" }}
                  >
                    {mapOptional(s, formatBookingStatus) ?? "ทั้งหมด"}
                  </Button>
                ))}
              </ButtonGroup>
            </Stack>
            <ItemsTotal count={total} />
            <TableContainer sx={{ height: "684px", overflowX: "hidden" }}>
              <Table stickyHeader>
                <TableHead>
                  <PTReservationTableHeaderRow />
                </TableHead>
                <DataTableBody loading={isLoading} data={data}>
                  {data.map((item) => (
                    <PTReservationTableRow key={item.id} data={item} />
                  ))}
                </DataTableBody>
              </Table>
            </TableContainer>
          </Stack>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
    </Stack>
  );
}
