import {
  Box,
  Card,
  CardContent,
  Stack,
  Button,
  TableContainer,
  Table,
  TableHead,
  Breadcrumbs,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { useAtom } from "jotai";

import { QueryTextField } from "@/components/QueryTextField";
import { DataTableBody } from "@/components/DataTableBody";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { getDocumentTitle } from "@/utils";
import { getCheckIns } from "@/services/checkIn";
import { selectedBranchAtom } from "@/atom/global";

import { CheckInTableRow, CheckInTableRowHeader } from "./CheckInTableRow";
import { CheckInDialog } from "../components/CheckInDialog";

export function CheckInListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedBranch] = useAtom(selectedBranchAtom);

  const dialog = searchParams.get("dialog");
  const query = searchParams.toString();

  const { data: raw, isLoading } = useQuery(
    ["check-ins", query, selectedBranch],
    () => getCheckIns(query)
  );

  const data = raw?.data ?? [];
  const total = raw?.total;

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  }

  function checkIn() {
    searchParams.set("dialog", "check-in");
    setSearchParams(searchParams);
  }

  const checkInDialog = {
    open: dialog === "check-in",
    onClose: onCloseDialog,
  };

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle("Check In")}</title>
      </Helmet>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 4.5 }}>
        <Typography color="text.primary">เช็คอิน</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack direction="row" alignItems="center" gap={2} mb={5}>
            <QueryTextField
              sx={{ width: 600 }}
              placeholder="ค้นหาด้วยเลขสมาชิก ชื่อ อีเมล หรือ เบอร์โทรศัพท์"
            />
            <Button
              variant="contained"
              size="large"
              sx={{ ml: "auto" }}
              onClick={checkIn}
            >
              เช็คอิน
            </Button>
          </Stack>
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <CheckInTableRowHeader />
              </TableHead>
              <DataTableBody loading={isLoading} data={data}>
                {data.map((item) => (
                  <CheckInTableRow key={item.id} data={item} />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
      <CheckInDialog {...checkInDialog} />
    </Box>
  );
}
